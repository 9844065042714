import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import FlatButton from 'material-ui/FlatButton';
import SessionCompletion from 'components/global/SessionCompletion';

const timeStyle = {
  fontSize: '28px',
  fontWeight: '600',
  color: '#b0c6f2',
  letterSpacing: '1.3px',
  fontFamily: 'Barlow',
};

const subtitles = {
  fontSize: '12px',
  color: '#5781be',
  fontWeight: '600',
};

class VideoBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeSessionModal: false,
      hideTime: false,
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({
      completeSessionModal: !prevState.completeSessionModal,
    }));
  };

  toggleTimer = () => {
    this.setState(prevState => ({
      hideTime: !prevState.hideTime,
    }));
  };

  render() {
    const {
      countdown,
      receivers,
      toggleFullscreen,
      exitFullScreen,
      appointment,
    } = this.props;

    return (
      <div className="videoBar">
        <div style={{ display: 'inline-block', paddingRight: '40px' }}>
          <h4 style={subtitles}>
            <FormattedMessage defaultMessage="TIME REMAINING" />
          </h4>
          {!this.state.hideTime ? (
            <h4 style={timeStyle}>{countdown}</h4>
          ) : (
            // eslint-disable-next-line react/jsx-no-literals
            <h4 style={timeStyle}> - - - - </h4>
          )}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="hide-timer"
            onClick={this.toggleTimer}
            role="presentation"
          >
            {!this.state.hideTime ? (
              <FormattedMessage defaultMessage="Hide Timer" />
            ) : (
              <FormattedMessage defaultMessage="Show Timer" />
            )}
          </a>
        </div>
        <div style={{ marginLeft: '12px', display: 'inline-block' }}>
          <h4 style={subtitles}>
            <FormattedMessage defaultMessage="Client" />
          </h4>
          <h4
            id="nameOfRemote"
            style={{
              ...timeStyle,
              color: 'black',
              fontWeight: ' 300',
              letterSpacing: '0px',
            }}
          >
            {receivers[0] &&
              receivers[0].id &&
              receivers[0].preferred_name_with_last_name}
            {receivers[1] && receivers[1].id && (
              <span>
                <br />
                {receivers[1].full_name}
              </span>
            )}
          </h4>
        </div>
        <div className="button-wrapper" style={{ marginLeft: 'auto' }}>
          <FlatButton
            className="flat-button-white"
            style={{
              marginRight: '10px',
              boxShadow: '0 0 10px 1px rgba(146, 180, 255, 0.2)',
            }}
            onClick={toggleFullscreen}
            label={<FormattedMessage defaultMessage="Fullscreen" />}
          />
          <FlatButton
            className="flat-button-green"
            onClick={() => {
              exitFullScreen();
              this.toggleModal();
            }}
            label={<FormattedMessage defaultMessage="Complete Session" />}
          />
        </div>
        {this.state.completeSessionModal && (
          <SessionCompletion
            open={this.state.completeSessionModal}
            appointmentId={appointment.id}
            handleClose={this.toggleModal}
            handlePostChange={this.props.disconnect}
          />
        )}
      </div>
    );
  }
}

VideoBar.propTypes = {
  disconnect: PropTypes.func.isRequired,
  countdown: PropTypes.string.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  exitFullScreen: PropTypes.func.isRequired,
  receivers: PropTypes.array.isRequired,
  appointment: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const appointment = _.get(state, 'session.appointment');
  return {
    appointment,
  };
}

export default connect(mapStateToProps)(VideoBar);
